
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { withNamespaces } from 'react-i18next';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
 
class OurMap extends Component {
  static defaultProps = {
    center: {
      lat: 41.0136809,
      lng: 28.9025191
    },
    zoom: 11
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC5pvoTBzYHHZBcDke2LN9DEKG-Hf6T7VM' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={41.0136809}
            lng={28.9025191}
            text="YIRTAR DEDEKTIFLIK"
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default  withNamespaces()(OurMap);
