/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

class Ticaret extends React.Component {


  getTranslation = (key) =>{
    
    return i18n.t(key);
  }

  componentDidMount() {
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="path"
              src={require("assets/img/blob.png")}
            />
            <img
              alt="..."
              className="path2"
              src={require("assets/img/path2.png")}
            />
            <img
              alt="..."
              className="shapes triangle"
              src={require("assets/img/triunghiuri.png")}
            />
            <img
              alt="..."
              className="shapes wave"
              src={require("assets/img/waves.png")}
            />
            <img
              alt="..."
              className="shapes squares"
              src={require("assets/img/patrat.png")}
            />
            <img
              alt="..."
              className="shapes circle"
              src={require("assets/img/cercuri.png")}
            /> 
            <div  className="section">
              
            <Container className="align-items-center">
            <h1 className="text-white" style={paddingTop5}>
            {this.getTranslation('serviceHeader')} <br />
               
                </h1>
                
                <Col  md="12" >
              <h5 className="text-on-back">01</h5>
                <h2 className="text-white">
                {this.getTranslation('serviceAdliHeader')}<br />
               
                </h2>
                <p style={pStyle} className="text-white mb-3">
                {this.getTranslation('serviceAdliInfo')}

                </p>                
                
              </Col>
        
            </Container>
            
            </div>  </div>
              
          <Footer />
        </div>
      </>
    );
  }
}
export default withNamespaces()(Ticaret);

const paddingTop5 = {
  paddingTop: "5%"
};

const pStyle = {
  fontSize: "18px"
}