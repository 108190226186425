/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, NavLink } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
  NavbarBrand,
  Navbar
} from "reactstrap";


import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';


class Summary extends React.Component {

  getTranslation = (key) =>{
    return i18n.t(key);
  }

  render() {
    return (
      <>
       
       <div>
          <section className="section-lg">
         
            <Container>
            <Row>
            <Col md="12">
               <div >
                
                  <blockquote>
                    <p className="blockquote blockquote-info">
                    {this.getTranslation('aboutText2')} <br />
                     
                    
                    </p>
                  </blockquote>
                </div>
               </Col>
                <Col md="6">
                  <div className="px-md-5">
                  
                   
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-delivery-fast" />
                          </div>
                          <div className="ml-3">
                          <h6 style={h6Style}>{this.getTranslation('uzmanEkip')} </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-tie-bow" />
                          </div>
                          <div className="ml-3">
                          <h6 style={h6Style}>{this.getTranslation('hukukEtikUygun')} </h6> 
                          {/* <NavLink
                            tag={Link} to="/borc-page"
                          ><h6>{this.getTranslation('sigorta')} </h6> </NavLink>  */}
                          </div>
                        </div>
                      </li>
                     
                     
                    </ul>
                  </div>
                </Col>
                <Col md="6">
                  <div className="px-md-5">
                  
                    
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-single-copy-04" />
                          </div>
                          <div className="ml-3">
                            <h6 style={h6Style}>{this.getTranslation('deneyimliKadro')}</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div className="icon icon-success mb-2">
                            <i className="tim-icons icon-book-bookmark" />
                          </div>
                          <div className="ml-3">
                            <h6 style={h6Style}>{this.getTranslation('uluslarMeslek')}</h6>
                          </div>
                        </div>
                      </li>
                    
                      
                    </ul>
                  </div>
                </Col>
                
               
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row>
                <Col md="3">
                  <Card className="card-coin card-plain">
                    <CardHeader style={CardHeaderStyle}>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/icon-01.png")}
                        style={cardImageStyle}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">27</h4>
                           <hr className="line-primary" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                         
                          <ListGroupItem>Yıllık Tecrübe</ListGroupItem>
                         
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-coin card-plain">
                    <CardHeader  style={CardHeaderStyle}>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/icon-02.png")}
                        style={cardImageStyle}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">680+</h4>
                        
                          <hr className="line-warning" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Mutlu Müşteri</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                     
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-coin card-plain">
                    <CardHeader  style={CardHeaderStyle}>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/icon-03.png")}
                        style={cardImageStyle}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">299+</h4>
                          
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Çözülen Dava</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                   
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="card-coin card-plain">
                    <CardHeader  style={CardHeaderStyle}>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/icon-04.png")}
                        style={cardImageStyle}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">19+</h4>
                         
                          <hr className="line-info" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Bulunan Kayıp Şahıs</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
         
        </div>
      </>
    );
  }
}

export default  withNamespaces()(Summary);

var CardHeaderStyle = {
    margin: "0"
}

var cardImageStyle = {
    width: "65px"
}

var h6Style = {
  fontSize: "15px"
}