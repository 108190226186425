/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import icon from '../../assets/img/icon.png';

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

class PageHeader extends React.Component {
  
  getTranslation = (key) =>{
    
    return i18n.t(key);
  }

  render() {
    return (
      <div className="page-header header-filter">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="squares square7" />
        <Container>
          <div className="content-center brand">
          <img src={icon} weign="300" height="300"/>
            <h1 className="h1-seo">   {this.getTranslation('name')}</h1>
            <h3 className="d-none d-sm-block">
            {this.getTranslation('info')}
            </h3>
          </div>
        </Container>
        
      </div>
    );
  }
}

export default withNamespaces()(PageHeader);
