/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";

import icon2 from '../../assets/img/icon-2.png';

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

import OurMap from '../examples/OurMap';


const carouselItems = [
  {
    src: require("assets/img/tv-1.jpg"),
    altText: "Slide 1",
   caption: ""
  },
  {
    src: require("assets/img/tv-2.jpg"),
    altText: "Slide 2",
    caption: ""
  },
  {
    src: require("assets/img/tv-3.jpg"),
    altText: "Slide 3",
    caption: ""
  },
  {
    src: require("assets/img/tv-4.jpg"),
    altText: "Slide 1",
    caption: ""
  }
];

let ps = null;

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1
    };
  }


  getTranslation = (key) =>{
    
    return i18n.t(key);
  }

  
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };
  render() {
    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <img
              alt="..."
              className="dots"
              src={require("assets/img/dots.png")}
            />
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png")}
            />
            <Container className="align-items-center">
              <Row>
                <Col lg="5" md="5">
                  <h1 className="profile-title text-left">{this.getTranslation('about')}</h1>
                  <h5 className="text-on-back">01</h5>
                  <p className="profile-description">
                  {this.getTranslation('aboutText')}
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                      className="btn-icon btn-round"
                      color="twitter"
                      href="https://twitter.com/yirtardedektif"
                      id="tooltip639225725"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip639225725">
                      Follow us
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon btn-round"
                      color="facebook"
                      href="https://www.facebook.com/yirtardedektiflik"
                      id="tooltip982846143"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip982846143">
                      Like us
                    </UncontrolledTooltip>
                    <Button
                      className="btn-icon btn-round"
                      color="dribbble"
                      href="https://www.instagram.com/yirtardedektiflik/"
                      id="tooltip951161185"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip951161185">
                      Follow us
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" lg="6" md="6">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid rounded-circle"
                        src={require("assets/img/logo.png")}
                      />
                    </CardHeader>
                    <CardBody>
                    <Table className="tablesorter" responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="header">{this.getTranslation('principles')}</th>
                              
                                <th className="header">{this.getTranslation('professionalTeam')}</th>
                              
                                <th className="header">{this.getTranslation('timely')}</th>                            
                              
                                <th className="header">{this.getTranslation('references')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{this.getTranslation('principlesText')}</td>
                             
                                <td>{this.getTranslation('professionalTeamTest')}</td>
                            
                                <td>{this.getTranslation('timelyTest')}</td>
                            
                                <td>{this.getTranslation('referencesTest')}</td>
                              </tr>
                            </tbody>
                          </Table>

                     
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <Row className="justify-content-between">
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <UncontrolledCarousel items={carouselItems} />
                  </Row>
                </Col>
                <Col md="5">
                  <h1 className="profile-title text-left"></h1>
                  <h5 className="text-on-back">02</h5>
                  <p className="profile-description text-left">
                  {this.getTranslation('aboutText2')}
                  </p>
                
                </Col>
              </Row>
            </Container>
          </div>
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">{this.getTranslation('contact')}</h1>
                      <h5 className="text-on-back">03</h5>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name</label>
                              <Input defaultValue="Mike" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Subject</label>
                              <Input defaultValue="About ..." type="text" />
                            </FormGroup>
                          </Col>
                          
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Phone</label>
                              <Input defaultValue="902124812061" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email address</label>
                              <Input
                                placeholder="mike@email.com"
                                type="email"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message</label>
                              <Input placeholder="Hello there!" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color="primary"
                          data-placement="right"
                          id="tooltip341148792"
                          type="button"
                        >
                          Send text
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip341148792"
                        >
                          Can't wait for your message
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" md="4">
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-square-pin" />
                    </div>
                    <div className="description">
                      <h4 className="info-title"> {this.getTranslation('addressInfo')}</h4>
                      <p>
                      Maltepe Mahallesi Eski Çırpıcı Yolu Sok.<br /> No: 8 Parima Plaza Kat: 14 Ofis: 1424 <br />Cevizlibağ/ İstanbul 
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-mobile" />
                    </div>
                    <div className="description">
                      <h4 className="info-title"> {this.getTranslation('phoneInfo')}</h4>
                      <p>
                        Ahmet YIRTAR <br />
                        +90 (212)-481-20-61 <br /> +90 (505)-366-25-34 <br /> +90 (532)-336-19-79
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-email-85" />
                    </div>
                    <div className="description">
                      <h4 className="info-title"> {this.getTranslation('mailInfo')}</h4>
                      <p>
                        Ahmet YIRTAR <br />
                        info@yirtardedektiflik.com <br />
                       
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <Container>
            <OurMap/>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}


export default withNamespaces()(ProfilePage);

//AIzaSyC5pvoTBzYHHZBcDke2LN9DEKG-Hf6T7VM