
  
// import React from 'react';
// import { i18n } from "i18next";

// i18n
//   .init({
//     interpolation: {
//       // React already does escaping
//       escapeValue: false,
//     },
//     lng: 'tr', // 'en' | 'es'
//     // Using simple hardcoded resources for simple example
//     resources: {
//       tr: {
//         translation: {
//             name: { label: 'YIRTAR DEDEKTİFLİK', },
//             info:{label:'Hukuk / Araştırma / Danışmanlık '},
//             home: { label: 'Anasayfa', },
//             about: { label: 'Hakkımızda', },
//             service: { label: 'Hizmetler', },
//         },
//       },
//       en: {
//         translation: {
//             name: { label: 'YIRTAR DEDEKTİVE', },
//             info:{label:'Private Investigation / Consulting '},
//             home: { label: 'Home', },
//             about: { label: 'About', },
//             service: { label: 'Service', },
//         },
//       },
//     },
//   })
// ;

    
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import backend from "i18next-xhr-backend";

i18n
  .use(LanguageDetector)
  .use(backend)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    // we init with resources
    resources: {
      tr: {
        translation: {
            name: 'YIRTAR DANIŞMANLIK',
            info:'Hukuk / Araştırma / Danışmanlık ',
            home: 'Anasayfa', 
            about:  'Hakkımızda', 
            service:  'Hizmetler', 
            aboutText :'Kurucumuz olan Ahmet Yırtar, emniyet teşkilatındaki 27 yıllık hizmetinin 20 yıllını İstanbul Cinayet Büro Amirliği\'nde tamamlamış ve emekli olduktan sonra uluslararası meslek standartları, hukuki ve etik kurallar çerçevesinde hizmet vermekte olan Yırtar Danışmanlık, Araştırma ve Danışmanlık Şirketini kurmuştur.',
            aboutText2: 'Müşterilerimize; uluslarası meslek standartlarında, hukuki ve etik kurallar çerçevesinde danışmanlık, araştırma ve danışmanlık hizmeti veriyoruz.',
            addressInfo: 'Ofisimizde bizi ziyaret edin',
            phoneInfo: 'Bizi arayın',
            mailInfo: 'Mail attın',
            contact :'İletişim',
            principles: 'Prensipler',
            professionalTeam: 'Profesyonel Ekip',
            timely: 'Zamanındalık',
            references:'Referanslar',
            principlesText: 'Hukuki ve etik kurallar çerçevesinde hizmet veriyoruz.',
            professionalTeamTest : 'Konusunda uzman, profesyonel ekibimiz ile çalışıyoruz.',
            timelyTest : 'Zamanındalık ilkemizden taviz vermiyoruz.',
            referencesTest :  'Bireysel ve Kurumsal güvenilir referanslar',
            ticaret : 'Ticaret Hukuku',
            sigorta : 'Sigorta Araştırması',
            borc : 'Borçlu Takibi',
            bocek : 'Böcek Tespit',
            isHaklari : 'İş ve İş Yeri Hakları Araştırma',
            sahne : 'Sahne Uyuşmazlığı',
            esTakibi : 'Eş Takibi',
            evlenmeden : 'Evlenmeden Araştırma',
            adres : 'Adres Tespiti',
            adli : 'Adli Araştırma',
            bilisim : 'Bilişim Araştırma',
            sosyalMedya : 'Sosyal Medya Araştırması',
            ourServices: 'Hizmetlerimiz Nelerdir?',
            serviceHeader: 'HİZMETLERİMİZ',
            serviceTicaretHeader: 'TİCARET HAK ARAŞTIRMA BİRİMİ',
            serviceTicaretInfo1: 'Ti̇caret hak araştırmasında; orji̇nal ürünleri̇n ve marka i̇si̇mleri̇ni̇n adına üreti̇len sahte ürünleri̇n tespi̇t edi̇lmesi̇.Tespi̇t edi̇len sahte ürünleri̇n üreti̇m aşamasından satış aşamasına kadar olan tüm evreleri̇n araştırılması,',
            serviceTicaretInfo2: 'Ti̇cari̇ anlaşmazlıklarla i̇lgi̇li̇ olarak; ki̇şi̇ veya şi̇rketler hakkında araştırma yapılması,',
            serviceTicaretInfo3: 'Kurumsal ve özel şi̇rketler kendi̇ i̇ç bünyeleri̇nde oluşabi̇lecek yolsuzluklar ve sui̇sti̇mallerle i̇lgi̇li̇ araştırma yapılması,',
            serviceTicaretInfo4: 'Kurumsal,özel şi̇rketler veya şahısların; resmi̇ yada gayri̇ resmi̇ ti̇cari̇ müşterekler/ortaklarla yapacakları ortaklık öncesi̇ ( holdi̇ng-anoni̇m ve li̇mi̇ted şi̇rket i̇le şahıslar) hakkında araştırma yapılması.',
            serviceSigortaHeader: ' SİGORTA HAKLARI ARAŞTIRMA BİRİMİ ',
            serviceSigortaInfo1: 'Sigorta araştırmasında gerçekleşmiş kaza ya da kazalar araştırılır ve bu kazaların raporları doğru mu diye kontrol edilir ve olay yeri araştırması yapılır.Sigorta ile ilgili tüm hileli muameleler ve dolandırıcılıklar araştırılır,konuyla ilgili tüm somut kanıtlar tespit edilir kayıp ve/veya çalıntı olan tüm eşyalar ve/veya araçlar araştırılır.',
            serviceSigortaInfo2: ' Sigorta şirketleri ve rent a car`a ait çalıntı ve kayıp araçların tespiti ve bulunması.',
            serviceBorcHeader: ' BORÇ HAKLARI ARAŞTIRMA BİRİMİ ',
            serviceBorcInfo: ' Borç hakları araştırma birimi borçluların,icra iflas dairelerinde aleyhinde takip var mı onu kontrol etmektir.Ayrıca borçlunun icra iflas dairelerinde tüm (menkul ve gayrimenkul) malları ile birlikte üçüncü kişilerdeki pay ve alacaklarının neden oluştuğu araştırılması kişilerin ve şirketlerin şahsi veya yazılı şekilde borcu bulunan,izini kaybettirmiş kişi veya kuruluşların yasal çerçeve doğrultusunda tespiti ve bulunması hizmeti verilmektedir.',
            serviceBocekHeader: 'GİZLİ KAMERA VE BÖCEK TESPİTİ',
            serviceBocekInfo: 'Özel şirketler,Kurumlar ve kişilerin iş yerleri,ikametleri ve araçlarında; Böcek,Gizli kamera ve buna benzeri materyallerin; İllegal kayıt ve dinleme cihazlarının,birlikte çalıştığımız işinin uzmanı kişiler tarafından profesyonel bir şekilde son teknoloji ekipmanlarıyla birlikte arama yapılmaktadır.',
            serviceIsHaklariHeader: 'İŞ VE İŞ YERİ HAKLARI ARAŞTIRMA BİRİMİ',
            serviceIsHaklariInfo1:'İş ve i̇ş yeri̇ hakları araştırması mazeret olarak gösteri̇len hususların ve hi̇leli̇ tüm durumların doğruluğunu araştırma bayi̇ ve acenteleri̇n fi̇nansal bakımdan araştırıp ve güncel mali̇ konum çözümlemeleri̇ni̇n yapılması,',
            serviceIsHaklariInfo2:'Her çeşi̇t ti̇cari̇ yapının bağlı olduğu acente, bayi̇li̇k, di̇stri̇bütör ve şubeleri̇n sözleşmede tebarüz etti̇ri̇len maddeler i̇le ti̇cari̇ koşullarına uyup uymadığının araştırılması hi̇zmeti̇ veri̇lmektedi̇r.',
            serviceSahneHeader: 'SAHNE VE FUAR UYUŞMAZLIĞI',
            serviceSahneInfo: 'Konser fuar ve festivallerde amaç çatışması bireysel ve kurumsal firmaların amaçlarının uyuşmazlığının tespiti yapılarak konusunda uzman adli bilirkişi hazırladığı raporları yargıya inti̇kal etmiş dava ve soruşturmalar delil olarak da sunulmaktadır.',
            serviceEsTakibiHeader: 'EŞ TAKİBİ',
            serviceEsTakibiInfo: 'Aile birliğini ve aile yapısını bozan davranışlar, eşlerin birbirlerine karşı olarak güvensiz ve şüpheli hareketleri veya yargıya intikal etmiş olan,boşanma davaları,velayet,miras,vesayet yapılarından kaynaklanan sorunlar ve hukuki davalarda yasaya uygun olarak delil toplanması hizmetidir.',
            serviceEvlenmedenHeader: 'EVLİLİK ÖNCESİ ARAŞTIRMA',
            serviceEvlenmedenInfo:' Evleneceğiniz kişinin sizden önceki hayatı hakkında ne kadar bilgi sahibisiniz,daha önceden evlilik yaşadı mi, yaşadıysa neden ayrıldı,kötü alışkanlıkları var mı, aile yapısı size uygun mu , nasıl biriyle evleneceksiniz kısacası ona ait her şeyi öğrenmek ve bu bilgiler doğrultusunda evleneceğiniz kişinin tüm geçmişini sizin için araştırıyoruz.',
            serviceAdresHeader: 'ADRES TESPİTİ',
            serviceAdresInfo: 'Yıllar önce görüşmediğiniz bir arkadaşınız,dostunuz,akrabanız,sevgiliniz veya alacaklınız sizden tamamen iletişimi kopmuş ise ulaşmak istediğiniz kişilerin kimlik bilgilerinden yola çıkarak, araştırılacak kişinin ikamet adresi, iş adresi ve/veya ticari faaliyetleri tespit edilir.',
            serviceAdliHeader: 'ADLİ OLAYLAR ARAŞTIRMA BİRİMİ',
            serviceAdliInfo: 'Kayıp şahıslar,şüpheli ölümler,intiharlar ve faili meçhul cinayet ve yaralama olayları ile ilgili olarak; Konusunda uzman deneyi̇mli ekiplerimiz tarafından olayların,en ince ayrıntı ve detayları ile birlikte profesyonel bir şekilde araştırılması yapılır.',
            serviceSosyalHeader: 'SOSYAL MEDYA ARAŞTIRMASI',
            serviceSosyalInfo:'Facebook,Twitter,Linkedin,Youtube vb. Gibi sosyal medya mecralarında açılan sahte ve provokatif hesapların kapatılması,hesabı açan şahısların tespit edilmesi, olumsuz yorum, fotoğraf,video, haber, vb. Gibi içeriklerin kaldırılması işlemleri acil müdahale misyonu ile sonuca ulaştırılmaktadır.',
            servicebilisimHeader: 'BİLİŞİM ARAŞTIRMA BİRİMİ',
            serviceBilisimInfo:'Uluslararası standartlardaki son teknoloji ürünü donanım ve yazılımlarla; her türlü cep telefon makinesi,hard disk,microdrive,floppy disk,CD/DVD/USB flash bellek, RAID, IOMEGA,ZIP/DRIVE,bellek kartları üzerinde,imaj alma veri kopyalama,veri silme,bilişim suçlarına yönelik veri araştırma ve şifre çözme işlemleri yapılmaktadır.',
            uzmanEkip:'Uzman Ekip',
            deneyimliKadro:'Deneyimli Kadro',
            hukukEtikUygun: 'Hukuki ve Etik kurallarına Uygun',
            uluslarMeslek :'Uluslararası Meslek Standartları'
            
        },
      },
      en: {
        translation: {
            name: 'YIRTAR INVESTIGATION', 
            info:'Private Investigation / Consulting ',
            home:  'Home', 
            about: 'About Us', 
            service: 'Service', 
            aboutText: 'Our founder, Ahmet Yırtar, has completed 20 years of his 27 years of service in Istanbul Police Department and retired and founded Yırtar Investigation, Research and Consultancy Company which serves in accordance with international professional standards, legal and ethical rules.',
            aboutText2: 'We provide research and consultancy services in accordance with international professional standards, legal and ethical rules.',
            addressInfo: 'Find us at the office',
            phoneInfo: 'Give us a ring',
            mailInfo: 'Send a mail',
            contact :'Contact',
            principles: 'Principles',
            professionalTeam: 'Professional Team',
            timely : 'Timely',
            references: 'References',
            principlesText: 'We provide services in accordance with international professional standards, legal and ethical rules',
            professionalTeamTest : 'We work with our experts and professional team.',
            timelyTest : 'We do not compromise our punctuality principle.',
            referencesTest :  'Individual and Corporate reliable references.',
            ticaret : 'Commercial law',
            sigorta : 'İnsurance research',
            borc : 'Deptor follow-up',
            bocek : 'Spy devices detection',
            isHaklari : 'Employee and employer rights research',
            sahne : 'Dispute investigation',
            esTakibi : 'Spouse tracking',
            evlenmeden : 'Marriage research',
            adres : 'Address detection',
            adli : 'Judicial investigation',
            bilisim : 'IT research',
            sosyalMedya : 'Social media research',
            ourServices: 'Our Services',
            serviceHeader: 'OUR SERVİCES',
            serviceTicaretHeader: 'TRADE RIGHTS RESEARCH UNIT',
            serviceTicaretInfo1: 'In the commercial rights investigation; Detection of original products and counterfeit products produced on behalf of brand names. Investigation of all stages from the production stage to the sales stage of detected counterfeit products,',
            serviceTicaretInfo2: 'In relation to commercial disputes; Research about individuals or companies,',
            serviceTicaretInfo3: 'Conducting research on corruption and abuse that may occur within the corporate and private companies` internal structures,',
            serviceTicaretInfo4: 'Corporate, private companies or individuals; conducting research on official or unofficial commercial partners / partners prior to their partnership (holdi̇ng-anoni̇m and limi̇ted corporations).',
            serviceSigortaHeader: 'INSURANCE RIGHTS RESEARCH UNIT',
            serviceSigortaInfo1: 'In the insurance investigation, the accidents that occurred are investigated, the accuracy of the reports of these accidents is checked and the crime scene investigation is made. ',
            serviceSigortaInfo2: 'All fraudulent transactions and frauds related to insurance are investigated. All concrete evidence on the subject is determined. All lost or stolen items or vehicles are investigated. Detection and finding of stolen and lost vehicles belonging to insurance companies and rent a car.',
            serviceBorcHeader: 'DEBT RIGHTS RESEARCH UNIT',
            serviceBorcInfo: 'Debt rights research unit; It is to check whether the debtors have a follow-up against the enforcement and bankruptcy offices.In addition, in the enforcement and bankruptcy offices of the debtor, all (movable and real estate) properties as well as third parties` shares and receivables are investigated, individuals and companies are identified and found in accordance with the legal framework.',
            serviceBocekHeader: 'HIDDEN CAMERA AND  BUGGING DEVICE',
            serviceBocekInfo: 'Private companies, institutions and persons in their workplaces, residences and vehicles; bugging device, hidden camera and similar materials; Illegal recording and listening devices are professionally searched with state-of-the-art equipment by experts in the business we work with.',
            serviceIsHaklariHeader: 'WORK AND WORKPLACE RIGHTS RESEARCH UNIT',
            serviceIsHaklariInfo1:'Investigating the accuracy of the issues and all the situations shown as an excuse for work and workplace rights research and making financial position analysis of the dealers and agencies,',
            serviceIsHaklariInfo2:'The service of investigating whether the agencies, distributorships, distributors and branches to which all kinds of commercial structures are affiliated comply with the terms of the trade with the clauses mentioned in the contract are provided.',
            serviceSahneHeader: 'STAGE AND FAIR MISMATCH',
            serviceSahneInfo: 'Conflict of purpose in concerts, fairs and festivals, the disagreement of the purposes of individual and corporate companies is determined and the reports prepared by expert judicial experts are presented as evidence.',
            serviceEsTakibiHeader: 'PARTNER TRACKING',
            serviceEsTakibiInfo: 'It is the service of collecting evidence in accordance with the law in behaviors that disrupt the family unity and family structure, unsafe and suspicious actions of spouses against each other, or divorce cases that have been submitted to the judiciary, problems arising from custody, inheritance, guardianship structures and legal cases.',
            serviceEvlenmedenHeader: 'PRE-MARRIAGE RESEARCH',
            serviceEvlenmedenInfo:'How much do you know about the life of the person you are marrying before you? Has he been married before? if he lived, why did he leave? Do they have bad habits? Does the family structure suit you? How will you marry? We are investigating the entire history of the person you will marry for you.',
            serviceAdresHeader: 'ADDRESS DETECTION',
            serviceAdresInfo: 'If a friend, friend, relative, lover or creditor whom you have not met years ago is completely disconnected from you, it is investigated based on the identity information of the people you want to reach. Current address, business address or commercial activities of the person are determined.',
            serviceAdliHeader: 'FORENSIC EVENTS RESEARCH UNIT',
            serviceAdliInfo: 'Regarding the missing persons, suspicious deaths, suicides and unsolved murders and injuries; Events are professionally investigated with the finest details and details by our experienced teams.',
            serviceSosyalHeader: 'SOCIAL MEDIA RESEARCH',
            serviceSosyalInfo:'Facebook, Twitter, Linkedin, Youtube etc. Closing fake and provocative accounts opened in social media such as social media channels, identifying the persons who opened the account, negative comments, photos, videos, news, etc. The removal of content such as such is finalized with the emergency response mission.',
            servicebilisimHeader: 'IT RESEARCH UNIT',
            serviceBilisimInfo:'With the latest technology hardware and software in international standards; All kinds of mobile phone, hard disk, microdrive, floppy disk, CD / DVD / USB flash memory, RAID, IOMEGA, ZIP / DRIVE, image taking, data copying, data deletion, data research and decryption for cyber crimes.',
            uzmanEkip:'Expert Team',
            deneyimliKadro:'Experiences Stuff',
            hukukEtikUygun: 'Compliant with Legal and Ethical Rules',
            uluslarMeslek :'International Occupational Standards'
        },
      }
    },
    fallbackLng: "tr",
    lng: "tr",
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }

  });

export default i18n;
